import { Button, Col, Divider, Form, Input, Modal, Row, Table, notification } from "antd";
import React, { useEffect, useState, useRef } from "react";
import { FaEye, FaFileSignature } from "react-icons/fa";
import DistributionDetail from "../../Components/distribuicao/detail";
import { getDistribution, getDistributions, getPlanification, getPlanifications,} from "../../API/StaticGetters";
import dayJS from "dayjs";
import PlanificationDetail from "../../Components/planificacao/detail";

const Historico = () => {
    const [loading, setLoading] = useState(false);
    const [dists, setDists] = useState([]);
    const [plans, setPlans] = useState([]);
    const [modalView, setModalView] = useState(false);
    const [modalReq, setModalReq] = useState(false);
    const [distribution, setDistribution] = useState({});
    const [planification, setPlanification] = useState({});
    const [selectedMenu, setSelectedMenu] = useState('dist');
    const reqPrint = useRef()


    useEffect(() => {
        localStorage.setItem('title', 'Histórico');
        localStorage.setItem('type', '2')
        getDist()
    }, [])

    const getDist = async () => {
        setLoading(true)
        try {
            let dists = await getDistributions(4)
            setDists(dists)
        } finally {
            setLoading(false)
        }
    }

    const getPlans =async()=>{
        setLoading(true)
        try {
            let plans = await getPlanifications('All')
            setPlans(plans)
        } finally {
            setLoading(false)
        }
    }

    

    const columns = [
        {
            title: 'Referência',
            dataIndex: 'reference',
            key: 'reference',
            width: 150,
        },
        {
            title: 'Unidade Organica',
            dataIndex: 'unidade',
            key: 'unidade',
            render: (text, record) => (
                <p>{record.unit.name}</p>
            ),
        },
        {
            title: 'Período',
            dataIndex: 'periodo',
            key: 'periodo',
            width: 200,
            render: (text, record) => (
                <p>{record.period.name}</p>
            ),
        },
        {
            title: 'Data de Registo',
            dataIndex: 'created_at',
            key: 'created_at',
            width: 200,
            render: (text, record) => (
                <p>{dayJS(record.created_at).format('DD/MM/YYYY HH:mm')}</p>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 120,
            render: (text, record) => (
                <p>{record.status.name}</p>
            ),
        },
        {
            title: 'Acções',
            key: 'action',
            width: 50,
            render: (text, record) => (
                <span className='flex justify-center items-center'>
                    <a onClick={() => handleView(record.id)}>
                        <FaEye
                            size={17}
                            color="#047d4b"
                        />
                    </a>
                </span>
            ),
        },
    ];

    const columnsPlan=[
        {
            title: 'Referência',
            dataIndex: 'reference',
            key: 'reference',
            width: 150,
        },
        {
            title: 'Unidade Organica',
            dataIndex: 'unidade',
            key: 'unidade',
            render: (text, record) => (
                <p>{record?.unit?.name}</p>
            ),
        },
        {
            title: 'Valor total',
            dataIndex: 'plan',
            key: 'value',
            width: 200,
            render: (text, record) => (
                <p>{`MZN ${record.plan.reduce((acc, item) => acc + parseFloat(item.value), 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`}</p>
            )
        },
        {
            title: 'Data de Registo',
            dataIndex: 'created_at',
            key: 'created_at',
            width: 200,
            render: (text, record) => (
                <p>{dayJS(record.created_at).format('DD/MM/YYYY HH:mm')}</p>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 120,
            render: (text, record) => (
                <p>{record.status.name}</p>
            ),
        },
        {
            title: 'Acções',
            key: 'action',
            width: 50,
            render: (text, record) => (
                <span className='flex justify-center items-center'>
                    <a onClick={() => handleViewPlan(record)}>
                        <FaEye
                            size={17}
                            color={"#047d4b"}
                        />
                    </a>
                </span>
            ),
        },
    ];

    const handleView = async (id) => {
        let dados = await getDistribution(id)
        setDistribution(dados)
        setModalView(true)
    }

    const handleViewPlan =async (record) => {
        let dados= await getPlanification(record.id)
        setPlanification(dados)
        setModalReq(true)
    }

    const handleMenu = (menu) => {
        if (menu === 'dist') {
            setSelectedMenu('dist')
            getDist()
        } else {
            setSelectedMenu(menu)
            getPlans()
        }
    }

    return (
        <div className='w-full flex flex-col px-5 py-4'>
            <p className="title-form">Histórico</p>
            <div className='menu-bar mt-2'>
                <div className={`menu-bar-item ${selectedMenu === 'dist' ? 'active' : ''}`} onClick={() => handleMenu('dist')}>
                    <p className='menu-bar-item-text'>Distribuições</p>
                </div>
                <div className={`menu-bar-item ${selectedMenu === 'req' ? 'active' : ''}`} onClick={() => handleMenu('req')}>
                    <p className='menu-bar-item-text'>Planificações</p>
                </div>
            </div>
            {selectedMenu === 'dist' && <>
                <Table
                    className='custom-table'
                    size="small"
                    columns={columns}
                    dataSource={dists}
                    loading={loading}
                    pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['5', '10', '15', '50', '100'] }}
                    locale={{
                        emptyText: 'Sem distribuições'
                    }}
                />
            </>}
            {selectedMenu === 'req' && <>
                <Table
                    className='custom-table'
                    size="small"
                    columns={columnsPlan}
                    dataSource={plans}
                    loading={loading}
                    pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['5', '10', '15', '50', '100'] }}
                    locale={{
                        emptyText: 'Sem planificações'
                    }}
                />
            </>}
            <Modal
                title={<p className='title'>DETALHES DA DISTRIBUIÇÃO</p>}
                open={modalView}
                onOk={() => setModalView(false)}
                onCancel={() => setModalView(false)}
                footer={null}
                width={700}
            >
                <DistributionDetail dados={distribution} />
            </Modal>

            <Modal
                title={<p className='title'>DETALHES DA PLANIFICAÇÃO</p>}
                open={modalReq}
                onOk={() => setModalReq(false)}
                onCancel={() => setModalReq(false)}
                footer={null}
                width={700}
            >
                <PlanificationDetail dados={planification} />
            </Modal>
        </div>
    )
}

export default Historico;