import axios from "axios"
import { getCountNotifications} from "./StaticGetters"

export const getNotifications=async()=>{
    let data= await getCountNotifications()
    let dict = {
        'pending_plan': data.pending_plan,
        'verified_plan': data.verified_plan,
        'viewed_plan': data.viewed_plan
    }
    sessionStorage.setItem('notifications', JSON.stringify(dict))
}