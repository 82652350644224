import React, { useState, useEffect } from 'react';
import { ArrowDownOutlined, ArrowUpOutlined, User } from '@ant-design/icons';
import { Card } from 'antd';
import { QuestionCircleOutlined, MailOutlined, PhoneOutlined, PlusCircleFilled } from '@ant-design/icons';
import Meta from 'antd/es/card/Meta';
import { Link } from 'react-router-dom';
import { FaFileContract, FaFileSignature, FaHistory, FaMoneyBill, FaMoneyCheck, FaUserAlt} from 'react-icons/fa';
import { MdEditDocument } from 'react-icons/md';
import { getNotifications } from '../API/getNotification';

const Menu = (props) => {

  const [notifications, setNotifications] = useState({
    pending_plan:0,
    verified_plan:0,
    viewed_plan:0,
  })

  useEffect(() => {
    localStorage.setItem('title', 'Bem-vindo a Plataforma');
    localStorage.setItem('type', '1');
    MyNotifications()
  }, [])

  const MyNotifications = async () => {
    await getNotifications()
    setNotifications({
      pending_plan: JSON.parse(sessionStorage.getItem('notifications'))?.pending_plan || 0,
      verified_plan: JSON.parse(sessionStorage.getItem('notifications'))?.verified_plan || 0,
      viewed_plan: JSON.parse(sessionStorage.getItem('notifications'))?.viewed_plan || 0,
    })
  }

  const links = [
    {
      title: 'Registrar Planificação',
      icon: <MdEditDocument style={{ fontSize: '60px', color: '#047D4B' }} />,
      link: '/nova-planificacao',
      group: 11
    },
    {
      title: 'Registrar Planificação',
      icon: <MdEditDocument style={{ fontSize: '60px', color: '#047D4B' }} />,
      link: '/nova-planificacao',
      group: 13
    },
    {
      title: 'Verificação de Planificação',
      icon: <FaFileSignature style={{ fontSize: '60px', color: '#047D4B' }} />,
      link: '/verificar-planificacao',
      group: 8,
      type: 'pending_plan'
    },
    {
      title: 'Vistoria de Planificação',
      icon: <FaFileSignature style={{ fontSize: '60px', color: '#047D4B' }} />,
      link: '/vistoria-planificacao',
      group: 9,
      type: 'verified_plan'
    },
    {
      title: 'Aprovar Planificação',
      icon: <FaFileSignature style={{ fontSize: '60px', color: '#047D4B' }} />,
      link: '/aprovar-planificacao',
      group: 7,
      type: 'viewed_plan'
    },
    {
      title: 'Aprovar Planificação',
      icon: <FaFileSignature style={{ fontSize: '60px', color: '#047D4B' }} />,
      link: '/aprovar-planificacao',
      group: 2,
      type: 'viewed_plan'
    },
    {
      title: 'Histórico',
      icon: <FaHistory style={{ fontSize: '60px', color: '#047D4B' }} />,
      link: '/historico',
      group: 'All'
    },
    {
      title: 'Meu Perfil',
      icon: <FaUserAlt style={{ fontSize: '60px', color: '#047D4B' }} />,
      link: '/meu-perfil',
      group: 'All'
    }
  ]

  const filteredLinks = links.filter(link => link.group === 'All' || link.group === parseInt(sessionStorage.getItem('access')))

  const getCurrentNotification = (type) => {
    switch (type) {
      case 'pending_plan':
        return notifications.pending_plan
      case 'verified_plan':
        return notifications.verified_plan
      case 'viewed_plan':
        return notifications.viewed_plan
      default:
        return 0
    }
  }

  return (
    <>
      <div className="grid grid-cols-2 sm:grid-cols-4  gap-4 p-2 sm:p-8">
      {filteredLinks.map((link, index) => (
          <Link to={link.link} key={index}>
            <Card
              bordered={false}
              hoverable
              cover={link.icon}
              className="relative"
            >
              <div className="text-sm sm:text-lg text-center">{link.title}</div>
              <p className={`absolute flex justify-center items-center top-0 right-7 w-4 h-4 bg-orange-500 text-white rounded-full ${link.type ? 'visible' : 'hidden'}`}>
                  {getCurrentNotification(link.type)}
                </p>
            </Card>
          </Link>
        ))}
      </div>
    </>
  );
};

export default Menu;