import axios from 'axios';
import { notification } from 'antd';

export const getProfilePicture = async () => {
    await axios.get(localStorage.getItem('url') + '/api/user/picture/',
        {
            headers: {
                'Authorization': `Token ${sessionStorage.getItem('token')}`,
            },
            responseType: 'blob'
        })
        .then(response => {
            sessionStorage.setItem('profile', URL.createObjectURL(response.data))
        }).catch(err => {
            
        }
        )
}

export const getUnit = async (id) => {
    let dados = {}
    await axios.get(localStorage.getItem('url') + '/api/organograma/unit/' + id + '/',
        {
            headers: {
                'Authorization': `Token ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
            dados = res.data
        }
        ).catch(err => {
            notification.error({
                message: 'Erro ao buscar estância'
            })
        })
    return dados;
}


export const getUser = async (id) => {
    let dados = {}
    await axios.get(localStorage.getItem('url') + '/api/user/' + id + '/',
        {
            headers: {
                'Authorization': `Token ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
            dados = res.data
        }
        ).catch(err => {
            notification.error({
                message: 'Erro ao buscar usuário'
            })
        })
    return dados;
}

export const getHistory = async () => {
    let dados = []
    await axios.get(localStorage.getItem('url') + '/api/user/history/',
        {
            headers: {
                'Authorization': `Token ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
            dados = res.data
        }).catch(err => {
            notification.error({
                message: 'Erro ao buscar historico'
            })
        })
    return dados;
}

export const getDistributions = async (status,dist) => {
    let dados = {}
    await axios.get(localStorage.getItem('url') + '/api/planification/distribution/',
        {
            headers: {
                'Authorization': `Token ${sessionStorage.getItem('token')}`
            },
            params:{
                status:status,
                dist:dist?true:false
            }
        }).then(res => {
            dados = res.data
        }
        ).catch(err => {
            notification.error({
                message: err.response.data.message
            })
        })
    return dados;
}

export const getDistribution = async (id) => {
    let dados = {}
    await axios.get(localStorage.getItem('url') + '/api/distribution/'+id+'/',
        {
            headers: {
                'Authorization': `Token ${sessionStorage.getItem('token')}`
            },
        }).then(res => {
            dados = res.data
        }
        ).catch(err => {
            notification.error({
                message: err.response.data.message
            })
        })
    return dados;
}

export const getExpenseParams = async (type) => {
    // 1 - Tipo de despesa
    // 2 - Eixos
    // 3 - Objectivo Estrategico
    let dados = {}
    await axios.get(localStorage.getItem('url') + '/api/expense/const/',
        {
            headers: {
                'Authorization': `Token ${sessionStorage.getItem('token')}`
            },
            params:{
                type:type
            }
        }).then(res => {
            dados = res.data
        }
        ).catch(err => {
            notification.error({
                message: err.response.data.message
            })
        })
    return dados;
}

export const getPlanifications = async (status) => {
    let dados = {}
    await axios.get(localStorage.getItem('url') + '/api/planification/',
        {
            headers: {
                'Authorization': `Token ${sessionStorage.getItem('token')}`
            },
            params:{
                status:status
            }
        }).then(res => {
            dados = res.data
        }
        ).catch(err => {
            notification.error({
                message: err.response.data.message
            })
        })
    return dados;
}

export const getPlanification =async(id)=>{
    let dados = {}
    await axios.get(localStorage.getItem('url') + '/api/planification/'+id+'/',
        {
            headers: {
                'Authorization': `Token ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
            dados = res.data
        }
        ).catch(err => {
            notification.error({
                message: err.response.data.message
            })
        })
    return dados;
}

export const getCountNotifications =async()=>{
    let dados = {}
    await axios.get(localStorage.getItem('url') + '/api/planification/count/',
        {
            headers: {
                'Authorization': `Token ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
            dados = res.data
        }
        ).catch(err => {
            notification.error({
                message: err.response.data.message
            })
        })
    return dados;
}

export const getUnitBalance = async (id) => {
    let dados = {}
    await axios.get(localStorage.getItem('url') + '/api/finance/balance/',
        {
            headers: {
                'Authorization': `Token ${sessionStorage.getItem('token')}`
            },
            params:{
                id:id
            }
        }).then(res => {
            dados = res.data
        }
        ).catch(err => {
            notification.error({
                message: err.response.data.message
            })
        })
    return dados;
}