import { Button, Col, Divider, Form, Input, Modal, Row, Table, notification } from "antd";
import React, { useEffect, useState } from "react";
import { FaEye } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { getPlanification, getPlanifications } from "../../API/StaticGetters";
import dayJS from "dayjs";
import axios from "axios";
import PlanificationDetail from "../../Components/planificacao/detail";

const Verificar = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [modalView, setModalView] = useState(false);
    const [distribution, setDistribution] = useState({});
    const [modalAprove, setModalAprove] = useState(false);
    const [typeForm, setTypeForm] = useState('')
    const [form] = Form.useForm();

    useEffect(() => {
        localStorage.setItem('title', 'Verificação de Planificações');
        localStorage.setItem('type', '2')
        getData()
    }, [])

    const getData = async () => {
        setLoading(true)
        try {
            let dists = await getPlanifications(1)
            setData(dists)
        } finally {
            setLoading(false)
        }
    }

    const columns = [
        {
            title: 'Referência',
            dataIndex: 'reference',
            key: 'reference',
            width: 150,
        },
        {
            title: 'Unidade Organica',
            dataIndex: 'unidade',
            key: 'unidade',
            render: (text, record) => (
                <p>{record?.unit?.name}</p>
            ),
        },
        {
            title: 'Data de Registo',
            dataIndex: 'created_at',
            key: 'created_at',
            width: 200,
            render: (text, record) => (
                <p>{dayJS(record.created_at).format('DD/MM/YYYY HH:mm')}</p>
            ),
        },
        {
            title: 'Acções',
            key: 'action',
            width: 50,
            render: (text, record) => (
                <span className='flex justify-center items-center'>
                    <a onClick={() => handleView(record.id)}>
                        <FaEye
                            size={17}
                            color="#047d4b"
                        />
                    </a>
                </span>
            ),
        },
    ];

    const handleView = async (id) => {
        let dados = await getPlanification(id)
        setDistribution(dados)
        setModalView(true)
    }

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const rowSelection = {
        selectedRowKeys,
        onChange: setSelectedRowKeys,
    };

    const handleAprove = (type) => {
        return () => {
            form.resetFields();
            if (selectedRowKeys.length === 0) {
                notification.error({
                    message: 'Sem Planificações Selecionadas',
                    description: 'Selecione pelo menos uma planificação para aprovar ou reprovar'
                });
                return;
            }
            switch (type) {
                case 1:
                    setTypeForm('Aprovar');
                    setModalAprove(true);
                    break;
                case 2:
                    setTypeForm('Reprovar');
                    setModalAprove(true);
                    break;
                default:
                    break;
            }
        }
    }

    const aproveProcess = async(values) => {
        setLoading(true)
        axios.put(
            localStorage.getItem('url') + '/api/planification/manage/',
            {
                ids: selectedRowKeys,
                action: typeForm === 'Aprovar' ? "Verificação da Planificação" : "Reprovou Planificação",
                status: typeForm === 'Aprovar' ? 8 : 5,
                message: values.message || ''
            },
            {
                headers: {
                    'Authorization': 'Token ' + sessionStorage.getItem('token')
                }
            },
        ).then(() => {
            notification.success({
                message: 'Planificações Atualizadas',
                description: 'Planificações atualizadas com sucesso'
            });
            getData()
            setModalAprove(false)
        }).catch(() => {
            notification.error({
                message: 'Erro ao Atualizar Planificações',
                description: 'Erro ao atualizar planificações, tente novamente'
            });
        }).finally(() => {
            setLoading(false)
            setSelectedRowKeys([])
        })
    }

    return (
        <div className='w-full flex flex-col px-5 py-4'>
            <p className="title-form">Planificações Aguardando Verificação</p>
            <Table
                className='custom-table mt-2'
                size="small"
                columns={columns}
                dataSource={data}
                loading={loading}
                pagination={{ defaultPageSize: 5, showSizeChanger: true, pageSizeOptions: ['5', '10', '15', '50', '100'] }}
                locale={{
                    emptyText: 'Sem planificações'
                }}
                rowKey={record => record.id}
                rowSelection={rowSelection}
                footer={() => {
                    return (
                        <div className="flex flex-row justify-between items-center w-full">
                            <p className="text" style={{ margin: 0 }}>{selectedRowKeys.length} Planificações Selecionadas</p>
                            <div className="flex flex-row justify-end gap-3 ">
                                <Button
                                    className="button-in"
                                    onClick={handleAprove(1)}
                                >
                                    Aprovar Selecionados
                                </Button>
                                <Button
                                    className="button-out"
                                    onClick={handleAprove(2)}
                                >
                                    Reprovar Selecionados
                                </Button>
                            </div>
                        </div>
                    )

                }}
            />
            <Modal
                title={<p className='title'>DETALHES DA PLANIFICAÇÃO</p>}
                open={modalView}
                onOk={() => setModalView(false)}
                onCancel={() => setModalView(false)}
                footer={null}
                width={700}
            >
                <PlanificationDetail dados={distribution} />
            </Modal>
            <Modal
                title={<p className='title'>{typeForm} Planificações</p>}
                open={modalAprove}
                onOk={() => setModalAprove(false)}
                onCancel={() => setModalAprove(false)}
                footer={null}
                width={600}
            >
                <div className="flex flex-col gap-3">
                    <Form form={form} layout="vertical" onFinish={aproveProcess}>
                        <p className="text">Tem certeza que deseja {typeForm.toLowerCase()} as planificações selecionadas?</p>
                        {typeForm === 'Reprovar' && (
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item
                                        label={<p className='label-input'>Motivo da Reprovação</p>}
                                        labelCol={{ span: 24 }}
                                        name="message"
                                        className='input'
                                        rules={[{ required: true, message: 'Por favor insira o motivo!' }]}
                                    >
                                        <Input.TextArea
                                            placeholder="Motivo da Reprovação"
                                            className='input-form'
                                            style={{ borderRadius: '0px' }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        )}
                        <div className="flex flex-row justify-end gap-3 mt-2">
                            <Button
                                className="button-out"
                                onClick={() => setModalAprove(false)}
                            >
                                Cancelar
                            </Button>
                            <Button
                                className="button-in"
                                htmlType="submit"
                            >
                                {typeForm}
                            </Button>
                        </div>
                    </Form>
                </div>
            </Modal>
        </div>
    )
}

export default Verificar;